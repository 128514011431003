#components-layout-demo-top-side-2 .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
}

.site-layout-background {
    background: #fff;
}
.containerBox{
    min-width: 100%;
    max-width: 100%;
    background-color: darkgreen;
}
.login-form {
    padding: 20px;
    max-width: 400px;
    min-width: 400px;
    max-height: 400px;
    min-height: 400px;
    background-color: white;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
    border-top-left-radius: ;
}
